import { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Tokens,
  getNetworks,
  getParamsNetwork,
  getParamsToken,
} from '../../../utils';
import ReactGA from 'react-ga';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AmountField from '../../Fields/AmountFieldComponents/AmountField/AmountField';
import WalletAddressField from '../../Fields/WalletAddressComponents/WalletAddressField/WalletAddressField';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import SelectField from '../../Fields/SelectFieldComponents/SelectField/SelectField';
import ScrollToTop from '../../ScrollToTopComponent/ScrollToTop';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';

interface OnrampFormikProps {
  rates: any;
  supportedFiat: object;
  supportedTokens: any;
  _VALIDATE_WALLET_ADDRESS: (
    address: string,
    network: string,
    setStatus: (v: boolean) => void
  ) => void;
  _GET_RATES: (token: string, network: string, currency: string) => void;
}

const OnrampFormik: FC<OnrampFormikProps> = ({
  rates,
  supportedFiat,
  supportedTokens,
  _VALIDATE_WALLET_ADDRESS,
  _GET_RATES,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const stage = location?.state?.stage;
  const data = location?.state?.data;
  const urlToken: string | any | null = params.get('token');
  const urlNetwork: string | any | null = params.get('network');
  const urlCurrency: string | any | null = params.get('currency');
  const urlFiat = urlCurrency !== null ? urlCurrency : '';
  const stringAmount: number | any | null = params.get('amount');
  const urlAmount = stringAmount !== null ? Number(stringAmount) : null;
  const disabledFields: string | any | null = params.get('disabledFields');

  const [status, setStatus] = useState<boolean>(true);
  const paramsToken = getParamsToken(supportedTokens, urlToken);
  const paramsNetwork = getParamsNetwork(supportedTokens, urlToken, urlNetwork);

  const [transactionData, setTransactionData] = useState<any | null>(
    stage != null ? data : null
  );

  const [selectedNetwork, setSelectedNetwork] = useState<string>(
    urlToken !== null && urlNetwork !== null && paramsNetwork !== undefined
      ? paramsNetwork.network
      : transactionData?.network
  );

  const [selectedToken, setSelectedToken] = useState<string>(
    urlToken !== null && paramsToken !== undefined
      ? paramsToken.symbol
      : transactionData?.token
  );

  const [selectedFiat, setSelectedFiat] = useState<string>(
    urlCurrency !== null ? urlFiat : transactionData?.currency
  );

  const fees = rates?.fees?.onramp_fee;
  const rate = rates?.onramp?.rate_in_ngn;
  const usdRate = rates?.onramp?.rate_in_usd;
  const totalFee = rates?.feeInDetail?.onramp?.totalFee;
  const gasFee = rates?.feeInDetail?.onramp?.fixedFee;
  const minValueCurrencyValue = 5 * usdRate;

  const networks = getNetworks(supportedTokens, selectedToken);

  const [currentStage, setCurrentStage] = useState<number>(
    stage != null
      ? stage
      : urlAmount !== null
      ? 5
      : urlCurrency !== null
      ? 4
      : urlToken !== null && urlNetwork !== null
      ? 3
      : urlToken !== null
      ? 2
      : 1
  );

  const handleNextStage = (fieldPosition: number) => {
    setCurrentStage(fieldPosition + 1);
  };

  const address = localStorage.getItem('address');

  function parseDisabledFields(inputString: any) {
    if (inputString !== null) {
      const fieldsString = inputString?.replace('disabledFields: ', '');
      const fieldsArray = fieldsString?.split(',');

      return fieldsArray?.map((field: any) => field.trim());
    }
  }

  const disabledFieldsArray = parseDisabledFields(disabledFields);

  const disabled = (value: string) => {
    const inLoop = disabledFieldsArray?.find((item: string) => item === value);
    return inLoop;
  };

  useEffect(() => {
    if (
      selectedToken !== undefined &&
      selectedNetwork !== undefined &&
      selectedFiat !== undefined
    ) {
      _GET_RATES(selectedToken, selectedNetwork, selectedFiat);
    }
  }, [_GET_RATES, selectedFiat, selectedNetwork, selectedToken]);

  return (
    <ScrollToTop>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          network:
            selectedNetwork !== null && selectedNetwork !== undefined
              ? selectedNetwork
              : '',
          currency: selectedFiat !== null ? selectedFiat : '',
          token:
            selectedToken !== null && selectedToken !== undefined
              ? selectedToken
              : '',
          currencyAmount: urlAmount !== null ? urlAmount : '',
          amount: transactionData !== null ? transactionData?.amount : '',
          walletAddress:
            address !== null && address.length > 1
              ? address
              : transactionData !== null
              ? transactionData?.walletAddress
              : '',
          toGet: transactionData !== null ? transactionData?.toGet : '',
          fee: transactionData !== null ? transactionData?.fee : '',
          percentageFee:
            transactionData !== null ? transactionData?.percentageFee : '',
        }}
        validationSchema={Yup.object({
          network: Yup.string().required('This is a required field'),
          currency: Yup.string().required('This is a required field'),
          token: Yup.string().required('This is a required field'),
          walletAddress: Yup.string().required('This is a required field'),
          amount: Yup.number()
            .typeError('Amount must be a number')
            .min(5, `Min amount is 5 ${selectedToken}.`)
            .max(10000, 'Please enter an amount not higher than $10,000.')
            .required('This is a required field'),
          currencyAmount: Yup.number()
            .typeError('Amount must be a number')
            .min(minValueCurrencyValue, `Min amount is 5 ${selectedToken}.`)
            .max(
              10000 * rate,
              'Please enter an amount not higher than $10,000.'
            )
            .required('This is a required field'),
        })}
        onSubmit={(values, { setErrors }) => {
          const errors = {};

          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            setTransactionData(values);
          } else {
            setErrors(errors);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, isValid }) => (
          <Form className="form-control" onSubmit={handleSubmit}>
            <SelectField
              name="token"
              data={supportedTokens !== undefined ? supportedTokens : Tokens}
              type={'token'}
              fieldPosition={1}
              title={'Select token'}
              shortTitle="Select token"
              currentStage={currentStage}
              setSelected={setSelectedToken}
              handleNextStage={handleNextStage}
              setCurrentStage={setCurrentStage}
              setFieldValue={(value: string) => {
                setFieldValue('token', value);
                if (value !== '') {
                  ReactGA.event({
                    category: 'on-ramp',
                    action: 'select token button click',
                    label: 'select_token',
                  });
                }
              }}
              isDisabled={disabled('token')}
              reInitData={selectedToken}
            />

            <SelectField
              name="network"
              data={networks}
              type={'network'}
              fieldPosition={2}
              title={'Select network'}
              shortTitle="Select network"
              currentStage={currentStage}
              setSelected={setSelectedNetwork}
              handleNextStage={handleNextStage}
              setCurrentStage={setCurrentStage}
              setFieldValue={(value: string) => {
                setFieldValue('network', value);
                if (value !== '') {
                  ReactGA.event({
                    category: 'on-ramp',
                    action: 'select network button click',
                    label: 'select_network',
                  });
                }
              }}
              isDisabled={disabled('network')}
              reInitData={selectedNetwork}
            />

            <SelectField
              data={supportedFiat}
              fieldPosition={3}
              type={'fiat'}
              name="currency"
              shortTitle="Select currency"
              currentStage={currentStage}
              handleNextStage={handleNextStage}
              setCurrentStage={setCurrentStage}
              setSelected={setSelectedFiat}
              setFieldValue={(value: string) => {
                setFieldValue('currency', value);
                if (value !== '') {
                  ReactGA.event({
                    category: 'on-ramp',
                    action: 'select currency button click',
                    label: 'select_currency',
                  });
                }
              }}
              title={'Select purchasing fiat currency'}
              reInitData={selectedFiat}
              isDisabled={disabled('currency')}
            />

            <AmountField
              fieldPosition={4}
              amountField={true}
              rate={rate}
              usdRate={usdRate}
              rampType="on"
              percentageFee={fees}
              crypto={values.token}
              title={'Enter amount'}
              name="currencyAmount"
              currentStage={currentStage}
              toGet={values.toGet}
              isDisabled={disabled('amount')}
              currency={values.currency}
              setCurrentStage={setCurrentStage}
              setFieldValue={setFieldValue}
              selectedNetwork={selectedNetwork}
              totalFee={totalFee}
              gasFee={gasFee}
              amount={values.amount}
            />

            <WalletAddressField
              lastStage={true}
              name="walletAddress"
              fieldPosition={5}
              currentStage={currentStage}
              title={'Enter address'}
              setStatus={setStatus}
              status={status}
              value={values.walletAddress}
              network={values.network}
              address={values.walletAddress}
              setFieldValue={(value: string) => {
                setFieldValue('walletAddress', value);
                if (value !== '') {
                  ReactGA.event({
                    category: 'on-ramp',
                    action: 'add wallet address click',
                    label: 'add_wallet',
                  });
                }
              }}
              isDisabled={disabled('address')}
              _VALIDATE_WALLET_ADDRESS={_VALIDATE_WALLET_ADDRESS}
            />

            <PrimaryButton
              onClick={() => {
                setCurrentStage(5);
                navigate('/confirm-transaction', {
                  state: {
                    data: { ...values, rate, gasFee },
                    backUrl: '/on-ramp',
                    rampType: 'on',
                  },
                });

                ReactGA.event({
                  category: 'on-ramp',
                  action: 'navigate to confirm transaction button click',
                  label: 'navigate_to_confirm_transaction',
                });
              }}
              validForm={isValid && status}
              type={'button'}
              title={'Submit'}
            />
          </Form>
        )}
      </Formik>
    </ScrollToTop>
  );
};

export default OnrampFormik;
