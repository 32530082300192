/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable autofix/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Fragment, useEffect, useState } from 'react';
import type { FC } from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import BackComponent from '../BackComponent/BackComponent';
import ImageComponent from '../ImageComponent/ImageComponent';
import {
  SolanaPay,
  amountSwitch,
  errorIcon,
  purpleCaution,
} from '../../assets';
import FieldStatus from '../Fields/FieldStatus/FieldStatus';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import { SolanaConfig } from '../interface';

import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
  _SOLANA_OFF_RAMP,
  _SOLANA_OFF_RAMP_MOBILE,
  _VERIFY_SOLANA_PAY,
} from '../../redux/transaction/transactionActions';
import { useNavigate } from 'react-router-dom';
import useWindowWidth from '../../hooks/useWindowWidth';
import Pusher from 'pusher-js';
import PendingState from '../PendingState/PendingState';

const { REACT_APP_RPC_API_KEY } = process.env;

export interface ConfirmTransactionProps {
  navigateBack: any;
  data: any;
  gasFee: number;
  backUrl: string;
  rampType?: 'on' | 'off';
  handleRoute: any;
  activeButton: boolean;
  email: string;
}

const ConfirmRampTransaction: FC<ConfirmTransactionProps> = ({
  navigateBack,
  data,
  gasFee,
  backUrl,
  rampType,
  handleRoute,
  activeButton,
  email,
}) => {
  console.log('🚀 ~ data:', data);
  const [windowWidth] = useWindowWidth();
  const btcUsd = data?.rate / data?.usdRate;
  const [txReference, setTxReference] = useState<string>('');

  const [solTxRef, setSolTxRef] = useState<string>('');
  const [isActiveButton, setActiveButton] = useState<boolean>(true);
  const [isTxInitiated, setIsTxInitiated] = useState<boolean>(false);
  const { wallet, publicKey, signTransaction, connected } = useWallet();

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const [redirectUrl, setRedirectUrl] = useState<any>();
  const [transactionTitle, setTransactionTitle] = useState<string>('');
  const [transactionMessage, setTransactionMessage] = useState<string>('');

  const [status, setStatus] = useState<
    | 'ramp_failed'
    | 'ramp_successful'
    | 'kyc_failed'
    | 'payment_reversed'
    | 'payment_received'
    | 'payment_delayed'
    | null
  >(null);

  const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
  });

  const channel = pusher.subscribe(`scalex_ramp_${txReference}`); // channel to subscribe to which is unique to each user

  channel.bind('ramp_successful', function (e: any) {
    setStatus('ramp_successful');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('ramp_failed', function (e: any) {
    setStatus('ramp_failed');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  useEffect(() => {
    if (
      status === 'ramp_failed' ||
      status === 'ramp_successful' ||
      status === 'payment_reversed' ||
      status === 'payment_delayed' ||
      status === 'payment_received'
    ) {
      navigate('/transaction-status', {
        state: {
          data,
          status,
          redirectUrl,
          rampType: 'off',
          transactionTitle,
          transactionMessage,
          reference: txReference,
        },
      });
    }
  }, [
    data,
    navigate,
    txReference,
    status,
    redirectUrl,
    transactionTitle,
    transactionMessage,
  ]);

  // const [serializedTransaction, setSerializedTransaction] = useState<any>('');
  const rpcEndpoint = `https://rpc.shyft.to?api_key=${
    REACT_APP_RPC_API_KEY as string
  }`;

  const onAndOffRampError = useSelector(
    (state: any) => state.transaction.onAndOffRampError
  );

  const percent = (Number(data.amount) / 100) * data.percentageFee;

  const fetchAndSignTransaction = async () => {
    if (!wallet || !publicKey) {
      alert('Please connect your wallet first.');
      return;
    }

    const connection = new Connection(rpcEndpoint, SolanaConfig.commitment);

    try {
      setActiveButton(false);
      dispatch(
        _SOLANA_OFF_RAMP({
          data,
          address: publicKey?.toString(),
          email,
          navigate,
          signTransaction,
          connection,
          setActiveButton,
          setTxReference,
          rampType,
        })
      );
    } catch (error) {
      console.error('Error fetching or signing transaction:', error);
      alert('Failed to complete transaction.');
    }
  };

  const fee = (data.amount / 100) * data?.percentageFee;

  const fetchAndSignTransactionMobile = async () => {
    try {
      setActiveButton(false);
      dispatch(
        _SOLANA_OFF_RAMP_MOBILE({
          data,
          email,
          navigate,
          setSolTxRef,
          setTxReference,
          setIsTxInitiated,
        })
      );
    } catch (error) {
      console.error('Error fetching or signing transaction:', error);
      alert('Failed to complete transaction.');
    } finally {
      console.log('some error');
    }
  };

  const logValueEvery3Sec = () => {
    setInterval(() => {
      if (isTxInitiated && solTxRef) {
        dispatch(_VERIFY_SOLANA_PAY(solTxRef, setIsTxInitiated));
      }
    }, 3000);
  };

  logValueEvery3Sec();

  return (
    <div className="confirm-transaction-wrapper">
      <PendingState isLoading={!isActiveButton} />
      <Fragment>
        {rampType === 'on' ? (
          <BackComponent
            data={{ data, stage: 5, loading: false }}
            url={`${backUrl}`}
            title={'Confirm transaction'}
          />
        ) : (
          <BackComponent
            data={{ data }}
            url={`${backUrl}`}
            title={'Confirm transaction'}
          />
        )}
      </Fragment>

      <div className="transaction-top-detail">
        <div className="token-container">
          <ImageComponent
            symbol={rampType === 'on' ? data?.currency : data?.token}
            size={'s'}
          />
          <p> {rampType === 'on' ? data?.currency : data?.token} </p>
        </div>
        <div className="img-container">
          <img src={amountSwitch} alt="" />
        </div>
        <div className="token-container">
          <ImageComponent
            symbol={rampType === 'on' ? data?.token : data?.currency}
            size={'s'}
          />
          <p>{rampType === 'on' ? data?.token : data?.currency}</p>
        </div>
      </div>

      <div className="transaction-table">
        <div className="bold-list">
          <p>You’re Buying</p>
          <h6>
            {rampType === 'on'
              ? String(Number(data.toGet) - Number(percent))
              : Number(data?.toGet)?.toLocaleString()}{' '}
            <span>{rampType === 'on' ? data?.token : data?.currency}</span>
          </h6>
        </div>
        <div className="bold-list">
          <p>You’ll pay</p>
          <h6>
            {rampType === 'on'
              ? parseFloat(data?.currencyAmount)?.toLocaleString()
              : parseFloat(data?.amount)
                  .toFixed(data?.network?.toLocaleLowerCase() === 'btc' ? 8 : 3)
                  ?.toLocaleString()}
            <span>{rampType === 'on' ? data?.currency : data?.token}</span>
          </h6>
        </div>

        <div className="light-list">
          <p className="title">Rate</p>
          <p className="detail">
            {data?.rate?.toLocaleString()} {data?.currency}
          </p>
        </div>

        <div className="light-list">
          <p className="title">{`Fee (${data?.percentageFee}%)`}</p>
          <p className="detail">
            {data?.network?.toLocaleLowerCase() === 'btc'
              ? fee.toFixed(8)
              : fee.toFixed(2)}{' '}
            {data?.token}
          </p>
        </div>

        <div className="light-list">
          <p className="title">${gasFee} Gas fee</p>
          <p className="detail">
            $
            {data?.network?.toLocaleLowerCase() === 'btc'
              ? (gasFee / btcUsd).toFixed(8)
              : gasFee}{' '}
            {data?.token}
          </p>
        </div>
        {rampType === 'on' ? (
          <div className="medium-list">
            <p className="title">Receiving address</p>
            <p className="address">{data?.walletAddress}</p>
          </div>
        ) : (
          <div className="medium-list">
            <p className="title">Receiving account</p>
            <p className="address">
              {data?.account_number} * {data?.bank_name}
            </p>
          </div>
        )}
      </div>

      <div className="lower-details">
        {rampType === 'on' && (
          <div className="info-container">
            <img src={purpleCaution} alt="caution" />
            <p>
              Please make sure you check that the wallet address you’ve added is
              correct. Sending to the wrong address will result in loss of funds{' '}
            </p>
          </div>
        )}

        <Fragment>
          {rampType === 'on' ? (
            <PrimaryButton
              validForm={null}
              type={'button'}
              title={`Pay ${parseFloat(
                data?.currencyAmount
              )?.toLocaleString()}  ${data?.currency?.toUpperCase()}`}
              onClick={handleRoute}
            />
          ) : (
            <PrimaryButton
              validForm={true}
              type={'button'}
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              title={`Pay ${parseFloat(data?.amount)
                .toFixed(data?.network?.toLocaleLowerCase() === 'btc' ? 8 : 3)
                ?.toLocaleString()}  ${data?.token?.toUpperCase()}`}
              activeButton={activeButton}
              onClick={handleRoute}
            />
          )}
        </Fragment>

        <>
          {rampType === 'off' && data?.network?.toLowerCase() === 'solana' && (
            <div className="group-button">
              {windowWidth > 1023 && <WalletMultiButton />}
              <>
                {windowWidth > 1023 ? (
                  <PrimaryButton
                    style={{
                      background: '#F3F5F7',
                      color: '#191C1F',
                      marginTop: 0,
                      maxWidth: '50%',
                    }}
                    validForm={connected}
                    type={'button'}
                    title={'Pay with Solana Pay'}
                    onClick={() => {
                      fetchAndSignTransaction().catch((error) => {
                        console.error(
                          'Error in fetchAndSignTransaction:',
                          error
                        );
                        alert('Failed to complete transaction.');
                      });
                    }}
                    icon={SolanaPay}
                    // activeButton={isActiveButton}
                  />
                ) : (
                  <PrimaryButton
                    style={{
                      background: '#F3F5F7',
                      color: '#191C1F',
                      marginTop: 0,
                    }}
                    validForm={null}
                    type={'button'}
                    title={'Pay with Solana Pay'}
                    onClick={() => {
                      fetchAndSignTransactionMobile().catch((error) => {
                        console.error(
                          'Error in fetchAndSignTransaction:',
                          error
                        );
                        alert('Failed to complete transaction.');
                      });
                    }}
                    icon={SolanaPay}
                    // activeButton={isActiveButton}
                  />
                )}
              </>
            </div>
          )}
        </>

        <PrimaryButton
          style={{ background: '#F3F5F7', color: '#191C1F' }}
          validForm={null}
          type={'button'}
          title={'Change Details'}
          onClick={navigateBack}
        />
      </div>
      <div className="onAndOffRampError">
        {onAndOffRampError !== null && (
          <FieldStatus
            icon={errorIcon}
            mode={'error'}
            errorMessage={onAndOffRampError}
          />
        )}
      </div>

      {/* <SolanaPayModal isOpen={isSolanaPayOpen} onClose={closeSolanaPay} /> */}
    </div>
  );
};

export default ConfirmRampTransaction;
