/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import Layout from '../../components/Layout/Layout';
import { greenCaution, onRampCheck, transferInfoBg } from '../../assets';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import CopyComponent from '../../components/CopyComponent/CopyComponent';
import { createStructuredSelector } from 'reselect';
import { selectOnRamp } from '../../redux/transaction/transactionSelectors';
import { connect } from 'react-redux';
import type { OnRampData } from '../../../types';
import CountdownTimer from '../../components/Countdown/Countdown';
import Pusher from 'pusher-js';

interface Props {
  onRamp: OnRampData;
}

const TransferInfo: FC<Props> = ({ onRamp }) => {
  const PAGA_BANK_CODE = 100002;
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data;
  const [redirectUrl, setRedirectUrl] = useState<any>();
  const [transactionTitle, setTransactionTitle] = useState<string>('');
  const [transactionMessage, setTransactionMessage] = useState<string>('');

  const percent = (Number(data.amount) / 100) * data.percentageFee;

  const [status, setStatus] = useState<
    | 'ramp_failed'
    | 'ramp_successful'
    | 'kyc_failed'
    | 'payment_reversed'
    | 'payment_received'
    | 'payment_delayed'
    | null
  >(null);

  useEffect(() => {
    (status === 'ramp_failed' ||
      status === 'ramp_successful' ||
      status === 'payment_reversed' ||
      status === 'payment_received') &&
      navigate('/transaction-status', {
        state: {
          status,
          reference: onRamp.reference,
          data,
          redirectUrl,
          rampType: 'on',
          transactionMessage,
          transactionTitle,
        },
      });
  }, [
    data,
    navigate,
    onRamp.reference,
    status,
    redirectUrl,
    transactionMessage,
    transactionTitle,
  ]);

  const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
  });

  const channel = pusher.subscribe(`scalex_ramp_${onRamp?.reference}`); // channel to subscribe to which is unique to each user

  channel.bind('ramp_successful', function (e: any) {
    setStatus('ramp_successful');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('ramp_failed', function (e: any) {
    setStatus('ramp_failed');
    setTransactionTitle(e.title);
    setTransactionMessage(e.message);
    setRedirectUrl(e.redirect);
  });

  channel.bind('payment_reversed', function (e: any) {
    setStatus('payment_reversed');
    setTransactionTitle(e.title);
    setTransactionMessage(e.message);
    setRedirectUrl(e.redirect);
  });

  channel.bind('payment_received', function (e: any) {
    setStatus('payment_received');
    setTransactionTitle(e.title);
    setTransactionMessage(e.message);
  });

  channel.bind('payment_delayed', function (e: any) {
    setStatus('payment_delayed');
    setTransactionTitle(e.title);
    setTransactionMessage(e.message);
  });

  useEffect(() => {
    const payload = {
      account_number: onRamp.bank_to_fund.account_number,
      bank_code: PAGA_BANK_CODE,
      from_amount: onRamp?.from_amount,
      to_amount: onRamp?.to_amount,
      reference: onRamp.reference,
    };
    window.parent.postMessage(payload, '*');
  }, [
    onRamp.bank_to_fund.account_number,
    onRamp?.from_amount,
    onRamp.reference,
    onRamp?.to_amount,
  ]);

  return (
    <Layout>
      <div className="transfer-info">
        <img className="top-page-bg" src={transferInfoBg} alt="" />

        <div className="page-title">
          <p>Transfer</p>
          <h5>
            {parseFloat(data?.currencyAmount).toLocaleString() || '--'}{' '}
            <span>{data?.currency?.toUpperCase() || ''}</span>
            <CopyComponent
              text={data?.currencyAmount || ''}
              icon={onRampCheck}
            />
          </h5>
        </div>

        <div className="transfer-into">
          <p className="transfer-into-title">Transfer into</p>
          <div className="account-container">
            <p>{onRamp?.bank_to_fund.bank_name || '--'}</p>
            <h6 className="account-number">
              {onRamp?.bank_to_fund.account_number || '--'}
              <CopyComponent
                text={onRamp?.bank_to_fund.account_number || '--'}
                icon={onRampCheck}
              />
            </h6>
          </div>
        </div>

        {/* <div className="ussd-container">
          <p>or try USSD transfer</p>
          <h6>
            {onRamp.bank_to_fund.ussd_short_code}
            <CopyComponent
              text={onRamp.bank_to_fund.ussd_short_code}
              icon={onRampCheck}
            />
          </h6>
        </div> */}

        <div className="amount-to-receive">
          <p>Amount to receive</p>
          <div className="amount-flex">
            <h6>
              {(
                parseFloat(data.toGet) - parseFloat(percent?.toString())
              ).toLocaleString() || '--'}
            </h6>
            <span>{data.token ? data.token : '--'}</span>
          </div>
        </div>

        <CountdownTimer />

        <div className="info-frame">
          <img src={greenCaution} alt="" />
          <p>
            Please ensure funds are sent from your personal account. Funds sent
            through an account that does not match your identification details
            will be reversed.
          </p>
        </div>

        <div className="partner-btn-container">
          {onRamp?.meta?.paymentButton && (
            <PrimaryButton
              type={'button'}
              validForm={null}
              icon={onRamp?.meta?.paymentButton?.logo}
              title={onRamp?.meta?.paymentButton?.text}
              style={{
                margin: 0,
                background: onRamp?.meta?.paymentButton?.backgroundColor,
              }}
              onClick={() => {
                window.location.href = onRamp?.meta?.paymentButton?.url
                  ? onRamp?.meta?.paymentButton?.url
                  : '';
              }}
            />
          )}
          <PrimaryButton
            validForm={null}
            type={'button'}
            title={'I’ve sent the money'}
            onClick={() => {
              navigate('/transaction-status', {
                state: {
                  reference: onRamp.reference,
                  data,
                  rampType: 'on',
                },
              });
            }}
            style={{ background: ' #F3F5F7', color: '#191C1F', margin: 0 }}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  onRamp: selectOnRamp,
});

export default connect(mapStateToProps, null)(TransferInfo);
