/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import Layout from '../../components/Layout/Layout';
import RampTopBar from '../../components/OnRampComponents/RampTopBar/RampTopBar';
import OnrampFormik from '../../components/OnRampComponents/OnrampFormik/OnrampFormik';
import { activeIcon } from '../../assets';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingState from '../../components/LoadingState/LoadingState';
import {
  _GET_RATES,
  _GET_SUPPORTED_FIAT,
  _GET_SUPPORTED_TOKEN,
  _VALIDATE_WALLET_ADDRESS,
} from '../../redux/transaction/transactionActions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectSupportedFiat,
  selectSupportedTokens,
  selectTransactionRates,
} from '../../redux/transaction/transactionSelectors';
import type { ISupportedTokenData } from '../../../types';

// import { fixedGasFee } from '../../utils';

interface Props {
  _GET_RATES: (token: string, network: string, currency: string) => void;
  _GET_SUPPORTED_TOKEN: () => void;
  _GET_SUPPORTED_FIAT: () => void;
  _VALIDATE_WALLET_ADDRESS: (
    address: string,
    network: string,
    setError: (v: boolean) => void
  ) => void;
  // rates: IRateData;
  rates: any;
  supportedTokens: ISupportedTokenData[];
  supportedFiat: ISupportedTokenData[];
}

const Onramp: FC<Props> = ({
  _GET_RATES,
  _GET_SUPPORTED_TOKEN,
  _VALIDATE_WALLET_ADDRESS,
  _GET_SUPPORTED_FIAT,
  rates,
  supportedTokens,
  supportedFiat,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageLoadState = location?.state?.loading;
  const [loading, setLoading] = useState<boolean>(
    pageLoadState !== undefined ? pageLoadState : true
  );

  const [params] = useSearchParams();
  const countdownStartTime: any = 1800;
  const paymentReceivedTime: any = 1800;

  // const getFee = (value: any, rampTypeFee: number) => {
  //   const pRampTypeFee = rampTypeFee / 100;

  //   const gasFee = fixedGasFee(network);
  //   const fee: any = pRampTypeFee * value + gasFee;
  //   return fee < 100 ? fee : 100;
  // };

  const email: string | undefined | null = params.get('email');
  const session: string | undefined | null | any = params.get('session');
  const address: string | any | null = params.get('address');
  const token: string | any | null = params.get('token');
  const network: string | any | null = params.get('network');
  const currency: string | any | null = params.get('currency');
  const stringAmount: number | any | null = params.get('amount');
  const skipRampForm: string | any | null = params.get('skipRampForm');
  const reference: string | any | null = params.get('reference');

  localStorage.setItem('reference', reference);
  localStorage.setItem('session', session);

  const loadingTime = skipRampForm === 'true' ? 5000 : 2000;

  const percentageFee = rates?.fees?.onramp_fee;
  const rate = rates?.onramp?.rate_in_ngn;
  console.log('🚀 ~ rate:', rate);
  const amount = Number(stringAmount) / rate;
  const numberFee = rates?.feeInDetail?.onramp?.totalFee;
  const percent = (Number(amount) / 100) * percentageFee;
  const toGet = String(amount - numberFee);
  console.log('🚀 ~ toGet:', toGet);
  const stringFee = String(numberFee);
  const currencyAmount = Number(stringAmount);

  if (session !== null) {
    localStorage.setItem('session', session);
  }

  if (address !== null) {
    localStorage.setItem('address', address);
  }

  if (email !== null) {
    localStorage.setItem('email', email);
  }

  localStorage.setItem('countdownStartTime', countdownStartTime);
  localStorage.setItem('paymentReceivedTime', paymentReceivedTime);

  useEffect(() => {
    _GET_SUPPORTED_TOKEN();
    _GET_SUPPORTED_FIAT();
    _GET_RATES(token, network, currency);
  }, [
    _GET_RATES,
    _GET_SUPPORTED_TOKEN,
    _GET_SUPPORTED_FIAT,
    token,
    network,
    currency,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      if (
        skipRampForm !== null &&
        skipRampForm !== undefined &&
        skipRampForm === 'true'
      ) {
        navigate('/verify-email', {
          state: {
            data: {
              email,
              amount,
              currency,
              currencyAmount,
              fee: stringFee,
              network,
              percentageFee,
              rate,
              toGet: Number(toGet) - Number(percent),
              token,
              walletAddress: address,
            },
            rampType: 'on',
          },
        });
      }
    }, loadingTime);
  }, [
    rate,
    stringFee,
    address,
    amount,
    currencyAmount,
    percentageFee,
    currency,
    toGet,
    email,
    navigate,
    token,
    network,
    skipRampForm,
    loadingTime,
    percent,
  ]);

  return (
    <Layout>
      {loading ? (
        <LoadingState />
      ) : (
        <div className="ramp-main">
          <RampTopBar icon={activeIcon} rampType="on" />
          <OnrampFormik
            rates={rates}
            supportedTokens={supportedTokens}
            supportedFiat={supportedFiat}
            _VALIDATE_WALLET_ADDRESS={_VALIDATE_WALLET_ADDRESS}
            _GET_RATES={_GET_RATES}
          />
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  rates: selectTransactionRates,
  supportedTokens: selectSupportedTokens,
  supportedFiat: selectSupportedFiat,
});

const mapDispatchToProps = (dispatch: any) => ({
  _GET_RATES: (token: string, network: string, currency: string) =>
    dispatch(_GET_RATES(token, network, currency)),
  _GET_SUPPORTED_TOKEN: () => dispatch(_GET_SUPPORTED_TOKEN()),
  _GET_SUPPORTED_FIAT: () => dispatch(_GET_SUPPORTED_FIAT()),
  _VALIDATE_WALLET_ADDRESS: (
    address: string,
    network: string,
    setError: (v: boolean) => void
  ) => dispatch(_VALIDATE_WALLET_ADDRESS(address, network, setError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Onramp);
